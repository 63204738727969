<template>
  <div class="container">
    <div v-if="!user.agreement" class="alert alert-info text-center">
      ليس لديك عقد بعد
    </div>
    <div v-else>
      <div class="panel mb-5">
        <div class="header">
          <span>العقد</span>
        </div>

        <div class="body p-4">
          <div class="text-center w-100 m-auto">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >بداية العقد</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.start"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >نهاية العقد</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.end"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >قيمة العقد</label
              >
              <div class="col-sm-8">
                <input
                  type="number"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.totalAmount"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >الكهرباء</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.electricity"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >الماء</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.water"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >المبالغ المدفوعة</label
              >
              <div class="col-sm-8">
                <input
                  type="number"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.paidAmount"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >المبالغ غير المدفوعة</label
              >
              <div class="col-sm-8">
                <input
                  type="number"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.unpaidAmount"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >طلبات محكمة التنفيذ</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.isCourtRequest ? 'يوجد' : 'لا يوجد'"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >مدة العقد</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="
                    durationTypes.find(
                      (type) => type.value === user.agreement.durationType
                    ).name || ''
                  "
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >نوع الدفعات</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="
                    paymentTypes.find(
                      (type) => type.value === user.agreement.paymentType
                    ).name || ''
                  "
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-muted text-right"
                >رقم العقد في إيجار</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.ejarNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel mb-5">
        <div class="header">
          <span>الدفعات</span>
        </div>

        <div class="body p-4">
          <div class="text-center w-50 m-auto">
            <div
              v-for="i in paymentsCount"
              :key="i"
              class="form-group text-right"
            >
              <label v-if="paymentsCount > 1" class="small"
                >{{ paymentTitles[i] }}:</label
              >
              <label v-else class="small">الدفعة:</label>

              <input
                class="form-control"
                placeholder=""
                type="text"
                :value="user.agreement['payment' + i]"
                disabled
              />

              <div class="row">
                <div class="col">
                  <label class="small">المدفوع</label>

                  <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    :value="user.agreement['paid' + i]"
                    disabled
                  />
                </div>
                <div class="col">
                  <label class="small">تاريخ الدفع</label>

                  <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    :value="user.agreement['paymentDate' + i]"
                    disabled
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label class="small">المتبقي</label>

                  <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    :value="user.agreement['unpaid' + i]"
                    disabled
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label class="small">الكهرباء</label>

                  <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    :value="user.agreement['electricity' + i]"
                    disabled
                  />
                </div>
                <div class="col">
                  <label class="small">الماء</label>

                  <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    :value="user.agreement['water' + i]"
                    disabled
                  />
                </div>
              </div>

              <hr />
            </div>
            <!-- <div class="form-group">
              <label class="small text-right float-right">الدفعة الأولى</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment1"
                />
              </div>

              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid1"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid1"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة الثانية</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment2"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid2"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid2"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة الثالثة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment3"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid3"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid3"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة الرابعة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment4"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid4"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid4"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة الخامسة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment5"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid5"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid5"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة السادسة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment6"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid6"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid6"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة السابعة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment7"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid7"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid7"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة الثامنة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment8"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid8"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid8"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة التاسعة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment9"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid9"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid9"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة العاشرة</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment10"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid10"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid10"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة الحادية عشر</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment11"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid11"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid11"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="small text-right float-right">الدفعة الثانية عشر</label>
              <div class="">
                <input
                  type="text"
                  class="form-control text-center"
                  disabled
                  :value="user.agreement.payment12"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label class="small float-right">المدفوع</label>
                  <input
                    type="number"
                    v-model="user.agreement.paid12"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label class="small float-right">المتبقي</label>

                  <input
                    type="number"
                    v-model="user.agreement.unpaid12"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div> -->

            <!-- <hr> -->
          </div>

          <!-- <button class="btn btn-block btn-primary mt-5">
            <fa icon="save" />
            <span>حفظ</span>
        </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from "@/mixins/auth";

export default {
  mixins: [AuthMixin],

  computed: {
    paymentsCount() {
      return this.paymentTypes.find(
        (type) => type.value === this.user.agreement.paymentType
      ).count;
    },
  },

  data() {
    return {
      paymentTitles: {
        1: "الدفعة الأولى",
        2: "الدفعة الثانية",
        3: "الدفعة الثالثة",
        4: "الدفعة الرابعة",
        5: "الدفعة الخامسة",
        6: "الدفعة السادسة",
        7: "الدفعة السابعة",
        8: "الدفعة الثامنة",
        9: "الدفعة التاسعة",
        10: "الدفعة العاشرة",
        11: "الدفعة الحادية عشرة",
        12: "الدفعة الثانية عشرة",
      },
      durationTypes: [
        { name: "شهري", value: "monthly" },
        { name: "ربع سنوي", value: "quarterly" },
        { name: "نصف سنوي", value: "semiannually" },
        { name: "سنوي", value: "yearly" },
      ],
      paymentTypes: [
        { name: "شهري", value: "monthly", count: 12 },
        { name: "دفعة واحدة", value: "1", count: 1 },
        { name: "دفعتان", value: "2", count: 2 },
        { name: "ثلاث دفعات", value: "3", count: 3 },
        { name: "أربع دفعات", value: "4", count: 4 },
      ],
    };
  },

  methods: {
    updateImage(e) {
      this.$refs.idCardPreview.src = URL.createObjectURL(e.target.files[0]);
    },
  },
};
</script>