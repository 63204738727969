<template>
  <div id="user-wrapper">
    <div style="display: none" id="page-loading"></div>
    <div id="op-loading" v-if="isLoading">
      <div class="inner"></div>
    </div>
    <div id="main-wrapper" :class="{'hide-side-menu': !showSideMenu}">
      <UserHeader />
      <UserSideMenu />

      <div id="page-content">
        <!-- <EmailVerificationAlert /> -->

        <div class="inner-content container">
          <div v-if="user.execution_requests" class="execution-requests">
            <div v-for="(executionRequest, index) in user.execution_requests" :key="index" class="alert alert-danger">{{ executionRequest.body }}</div>
          </div>

          <!-- @if(session()->has('message'))
          <div class="alert alert-success">
            <button type="button" class="close" data-dismiss="alert">
              <span aria-hidden="true">&times;</span>
            </button>
            {{ session()->get('message') }}
          </div>
          @endif @if(session()->has('error'))
          <div class="alert alert-danger">
            <button type="button" class="close" data-dismiss="alert">
              <span aria-hidden="true">&times;</span>
            </button>
            {{ session()->get('error') }}
          </div>
          @endif -->
          <router-view />
        </div>
      </div>

      <div id="bottom-push"></div>
    </div>

    <UserFooter />
  </div>
</template>

<script>
import UserHeader from "@/components/general/user-header";
import UserSideMenu from "@/components/general/user-side-menu";
import UserFooter from "@/components/general/user-footer";
import { mapGetters } from "vuex";
// import EmailVerificationAlert from "@/components/alerts/email-verification";
import GeneralMixin from '@/mixins/general'
import AuthMixin from '@/mixins/auth'

export default {
  mixins: [GeneralMixin, AuthMixin],
  
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },

  components: {
    UserHeader,
    UserSideMenu,
    UserFooter,
    // EmailVerificationAlert,
  },
};
</script>

<style lang="scss">
@import "../assets/css/user.scss";
</style>